import api from '../library/axios';

export default {
  getList({ page, search, workspace_id }) {
    return api({
      method: 'get',
      url: `/api/products/${workspace_id}`,
      params: {
        page,
        search,
      },
    });
  },
  create({ workspace_id, data }) {
    return api({
      method: 'post',
      url: `/api/products/${workspace_id}`,
      data,
    });
  },
  update({ id, workspace_id, data }) {
    return api({
      method: 'patch',
      url: `/api/products/${workspace_id}/${id}`,
      data,
    });
  },
  delete({ id, workspace_id }) {
    return api({
      method: 'delete',
      url: `/api/products/${workspace_id}/${id}`,
    });
  },
  uploadAttachment({ workspace_id, data }, uploadHandler = null) {
    return api({
      method: 'post',
      url: `/api/products/${workspace_id}/attachment`,
      data,
      uploadHandler,
    });
  },
};
