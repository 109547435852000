<template>
  <b-modal v-model="showModal" title="Quick Reply" hide-footer size="lg" @hide="onHide">
    <div class="row align-items-center">
      <div class="col-9 col-sm-9">
        <form class="search-form" @submit.prevent="doSearch()">
          <div class="input-group border rounded-sm">
            <div class="input-group-prepend">
              <div class="input-group-text border-0 rounded-sm">
                <FeatherIcon type="search" class="icon-md cursor-pointer" />
              </div>
            </div>
            <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search quick reply by title...">
          </div>
        </form>
      </div>
      <div class="col-3 col-sm-3">
        <button class="btn btn-primary" @click="doSearch()">Search</button>
      </div>
    </div>
    <div class="table-responsive mt-2">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Title</th>
            <th>Content</th>
            <th>Type</th>
            <th colspan="4">#</th>
          </tr>
        </thead>
        <tbody v-loading="loading.qrlist">
          <tr v-for="(qr) in qrList" :key="qr.id">
            <td>{{ qr.title }}</td>
            <td>
              <!-- <FeatherIcon type="image" size="14px" v-if="qr.body_type == 'image'" /> -->
              <i class="el-icon-picture-outline" v-if="qr.body_type == 'image'"/>
              <i class="el-icon-video-camera" v-if="qr.body_type == 'video'"/>
              <i class="el-icon-document" v-if="qr.body_type == 'document'"/>
              <span>
                {{ qr.body_type == 'text' ? truncate(qr.body, { length: 50 }) : truncate(qr.caption || qr.filename, { length: 50 }) }}
              </span>
            </td>
            <td>{{ qr.body_type }}</td>
            <td>
              <el-button @click="appendQr(qr)" size="mini">Use</el-button>
            </td>
          </tr>
        </tbody>
      </table>
      <el-empty :image="emptyImage" v-if="totalRow == 0" description="No data"></el-empty>
      <b-pagination v-if="totalRow > 10" v-model="currentPage" :total-rows="totalRow" :per-page="10"/>
    </div>
    <div class="d-flex flex-row-reverse">
      <b-button @click="showModalQr = false" variant="secondary">{{ $t('general.close') }}</b-button>
    </div>
  </b-modal>
</template>
<script>
import { truncate } from 'lodash';
import popupErrorMessages from '@/library/popup-error-messages';
import emptyImage from '@/assets/images/undraw/empty.svg';
import qrApi from '@/api/quickReplies';

export default {
  name: 'ConvoQuickReply',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      truncate,
      emptyImage,
      loading: {
        qrlist: false,
      },
      page: 1,
      qrList: [],
      totalRow: 0,
      showModal: false,
      search: '',
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.loadList();
      },
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  methods: {
    doSearch() {
      this.loadList();
    },
    async loadList() {
      this.loading.qrlist = true;
      const response = await qrApi.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.page,
        search_keyword: this.search,
      }).catch(() => {});
      this.loading.qrlist = false;
      await popupErrorMessages(response);
      this.qrList = response.data.rows;
      this.totalRow = response.data.total;
    },
    appendQr(data) {
      this.$emit('appendQr', data);
    },
    onHide() {
      this.$emit('onHide');
    },
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.show) {
        this.loadList();
      }
    },
  },
};
</script>
