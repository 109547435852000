import api from '../library/axios';

export default {
  bankList() {
    return api({
      method: 'get',
      url: '/api/bank',
    });
  },
  uploadAttachment({ workspace_id, data }, uploadHandler = null) {
    return api({
      method: 'post',
      url: `/api/settlement-accounts/${workspace_id}/attachment`,
      data,
      uploadHandler,
    });
  },
  createSettlementAccount({ workspace_id, data }) {
    return api({
      method: 'post',
      url: `/api/settlement-accounts/${workspace_id}`,
      data,
    });
  },
  listSettlementAccount({ workspace_id }) {
    return api({
      url: `/api/settlement-accounts/${workspace_id}`,
    });
  },
  listTransactions({ workspace_id, query }) {
    return api({
      url: `/api/transactions/${workspace_id}`,
      params: query,
    });
  },
  detailTransaction({ workspace_id, transaction_id, query }) {
    return api({
      url: `/api/transactions/${workspace_id}/${transaction_id}`,
      params: query,
    });
  },
  createTransaction({ workspace_id, data }) {
    return api({
      method: 'post',
      url: `api/transactions/${workspace_id}`,
      data,
    });
  },
};
