import api from '../library/axios';

export default {
  getList(workspace_id, page, search) {
    return api({
      method: 'get',
      url: `/api/agent_groups/${workspace_id}`,
      params: {
        page,
        search,
      },
    });
  },
  create({ name, workspace_id }) {
    return api({
      method: 'post',
      url: `/api/agent_groups/${workspace_id}`,
      data: {
        name,
      },
    });
  },
  update({ name, workspace_id, id }) {
    return api({
      method: 'patch',
      url: `/api/agent_groups/${workspace_id}/${id}`,
      data: {
        name,
      },
    });
  },
  getListMember(workspace_id, group_id, query) {
    return api({
      method: 'get',
      url: `/api/agent_groups/${workspace_id}/${group_id}/members`,
      params: query,
    });
  },
  removeMember(workspace_id, group_id, data) {
    return api({
      method: 'delete',
      url: `/api/agent_groups/${workspace_id}/${group_id}/members`,
      data,
    });
  },
};
