<template>
  <b-modal v-model="showModal" title="Quoted Message" hide-footer size="md" @hide="onHide">
    <div class="chat-wrapper" style="height: calc(100vh - 60px - 220px)">
      <div class="chat-content">
        <div class="chat-body">
          <ul class="messages">
            <li
              class="message-item"
              :class="message.formattedType">
              <div v-if="message.type === 'in'" class="identicons" v-html="identicons(conversation.id)"/>
              <div class="content" :id="'message-' + message.id" :style="isFromAgent(message) ? 'margin-right: 15px' : ''">
                <div class="message" :class="{ 'has-quote': message.context && message.context.body }">
                  <div class="bubble">
                    <!-- For Quoted Message -->
                    <div v-if="message.context && message.context.body" class="quoted-message">
                      <b>{{ message.context.type === 'out' ? $t('general.you') : message.context.contact.name }}</b><br/>
                      <message-item @handlePreview="handlePreview" :is_context="true" :message="message.context"/>
                    </div>
                    <message-item @handlePreview="handlePreview" :message="message"/>
                  </div>
                  <span>
                    <i :class="[message.channel_icon, message.channel_color]"/>
                    {{ message.formattedTime }}
                    <i :class="{
                      'status-read': message.status === 'read',
                      'mdi-check-all': ['sent', 'delivered', 'read'].includes(message.status),
                      'mdi-clock': message.status == 'pending',
                      'mdi-close-circle-outline': message.status === 'failed',
                      'text-danger': message.status === 'failed'
                      }" v-if="message.type == 'out'" class="mdi"/>
                  </span>
                  <span v-if="message.type === 'out'">{{ isFromAgent(message) ? message.user.name : '' }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex flex-row-reverse">
        <b-button @click="showModal = false" variant="secondary">{{ $t('general.close') }}</b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { toSvg } from 'jdenticon';
import MessageItem from '@/ui/components/MessageItem.vue';

export default {
  name: 'PreviewQuotedMessage',
  components: {
    MessageItem,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    identicons(value) {
      return toSvg(value, '40');
    },
    isFromAgent(message) {
      return message.type === 'out' && message.body_type !== 'system' && message.user.name;
    },
    handlePreview(message) {
      this.$parent.handlePreview(message);
    },
  },
  watch: {
    show() {
      this.showModal = this.show;
    },
  },
};
</script>
<style>
[data-initials]:before {
  background: #8CC8ED;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
[data-initials-ticket] {
  --initial-ticket-color: #192A53;
}
[data-initials-ticket]:before {
  /* background: #192A53; */
  background-color: var(--initial-ticket-color);
  font-size: 8px;
  color: white;
  opacity: 1;
  content: attr(data-initials-ticket);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 1em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.identicons {
  vertical-align: top;
  margin-right: 1em;
}
/*quoted message*/
.message-item .content{
  position: relative;
}
.quoted-message{
  width: auto;
  display: inline-block;
  background: #DBDEED;
  color: rgba(24,41,83,0.5);
  padding: 8px 15px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  position: relative;
  z-index: 2;
  margin-bottom: -10px;
  transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
}
.quoted-message:hover {
  color: rgba(24,41,83,0.7);
}
.message-item.me{
  text-align: left;
}
.message.has-quote{
  margin-top: 16px;
}

/* reply area */
.input-group input.private,
.input-group textarea.private,
.nav-tabs .nav-link.active#private-tab{
  background: #FBFBF3;
}

.chat-footer .search-form .input-group{
  height: 40px !important;
  position: relative;
}

textarea#chatForm,
textarea#privateForm{
  padding: 12px 20px;
  height: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.pr{
  position: relative;
}

.status-read{
  color: rgb(52, 183, 241);
}
.inbound-text{
  color: rgb(37, 211, 102)
}

/* Product */
.thumbnail-product {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.thumbnail-product::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}
</style>
<style lang="scss">
.messages .message-item {
  &.system {
    margin: auto;
    .content {
      order: 1;
      margin: auto !important;
      color: #fff;
      .bubble {
        background: #6C7A89;
        border-radius: 5px;
        margin: auto;
        align-self: center;
        p {
          text-align: center;
        }
      }
      span {
        text-align: center;
        display: block;
      }
    }
  }
}

.messages .message-item {
  &.private_note {
    margin: auto;
    .content {
      order: 1;
      margin: auto !important;
      color: #fff;
      .bubble {
        background: #fff3d5;
        color: #000;
        border-radius: 5px;
        margin: auto;
        align-self: center;
        p {
          text-align: center;
        }
      }
      span {
        text-align: center;
        display: block;
      }
    }
  }
}

.callout {
  padding: 10px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 5px;
  background:rgba(24,41,83,0.1);
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}
@each $name,$color in (default,#777),(primary,#428bca),(success,#5cb85c),(danger,#d9534f),(warning,#f0ad4e),(info,#5bc0de), (bdc,#29527a) {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}
</style>
