<template>
  <b-modal v-model="showModal" :title="modal_title" hide-footer @hide="onHide">
    <b-form @submit.prevent="submitMergeContact" @reset="onHide">
      <b-form-group :label="$t('conversations.merge_contact.title.parent')">
        <el-select filterable remote :remote-method="handlerFilterContact" class="w-100" v-model="form.parent_id">
          <el-option :disabled="selectedContact.channel === item.channel || !isEmpty(item.parent_id)" v-for="item in list_contact" :key="item.id" :value="item.id" :label="item.name">
            <span style="float: left">{{ item.name }}</span>
            <span :class="item.color" style="float: right; font-size: 13px"><i :class="item.icon"></i></span>
          </el-option>
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.parent_id.$invalid" v-if="v$.form.parent_id.$errors[0]">
          {{ $t('validation.' + v$.form.parent_id.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex flex-row-reverse">
        <el-button @click="submitMergeContact" :loading="loading.merge_contact" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
        <b-button @click="onHide" type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import { isEmpty } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import popupErrorMessages from '@/library/popup-error-messages';
import contactApi from '../../../api/contacts';

export default {
  name: 'ConvoMergeContact',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        parent_id: {
          required,
        },
        target_id: {
          required,
        },
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedContact: {
      type: Object,
      default() { return {}; },
    },
    conversation: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      isEmpty,
      loading: {
        merge_contact: false,
        list_contact: false,
      },
      form: {
        target_id: '',
        parent_id: '',
      },
      showModal: false,
      list_contact: [],
      timeout: null,
      channelList: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
      ],
      loaderStack: 0,
      loader: null,
    };
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.show) {
        this.form.target_id = this.selectedContact.id;
        this.getContactList();
      }
    },
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    modal_title() {
      return `${this.$t('conversations.merge_contact.modal_title')} ${this.selectedContact.name} ${this.$t('conversations.merge_contact.sub_modal_title')}`;
    },
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    onHide() {
      this.$emit('onHide');
    },
    handlerFilterContact(value) {
      this.timeout = setTimeout(() => {
        this.getContactList(value);
      }, 500);
    },
    async getContactList(keyword) {
      await contactApi.getListAll({
        workspace_id: this.activeWorkspace._id,
        search: keyword,
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.list_contact = response.data.rows.map((v) => {
          const ch = this.channelList.find((c) => c.value === v.channel);
          v.icon = ch.icon;
          v.color = `text-${ch.value}`;
          return v;
        });
      }).catch(() => {});
    },
    async submitMergeContact() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      this.showLoader();
      this.loading.merge_contact = true;
      await contactApi.mergeContact({
        workspace_id: this.activeWorkspace._id,
        target_id: this.form.target_id,
        parent_id: this.form.parent_id,
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.$message({
          type: 'success',
          message: this.$t('general.success'),
        });
        this.showModal = false;
        this.onHide();
        this.$parent.resetListConversation();
      }).catch(() => {});
      this.loading.merge_contact = false;
      this.hideLoader();
    },
  },
};
</script>
