<template>
  <b-modal v-model="showModal" title="Edit Contact" hide-footer @hide="onHide">
    <b-form @submit.prevent="updateContact" @reset="onHide">
      <b-form-group :label="$t('conversations.contact.title.name')">
        <b-form-input
          v-model="v$.form.name.$model"
          type="text"
          :placeholder="$t('conversations.contact.placeholder.name')"
          required />
        <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
          {{ $t('validation.' + v$.form.name.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex flex-row-reverse">
        <el-button @click="updateContact" :loading="loading.update_contact" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
        <b-button @click="onHide" type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import contactApi from '@/api/contacts';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'ConvoEditContact',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedContact: {
      type: Object,
      default() { return {}; },
    },
    conversation: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      loading: {
        update_contact: false,
      },
      form: {
        name: '',
      },
      showModal: false,
    };
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.show) {
        this.form = this.selectedContact;
      }
    },
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  methods: {
    async updateContact() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.loading.update_contact = true;
      await contactApi.update({
        workspace_id: this.activeWorkspace._id,
        id: this.form.id,
        data: {
          display_name: this.form.name,
        },
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.$message({
          type: 'success',
          message: this.$t('general.success'),
        });
        this.showModal = false;
        this.onHide();
        this.$parent.showDetailChat({ conversation_id: this.conversation.id, reload_history: false });
      }).catch(() => {});
      this.loading.update_contact = false;
    },
    onHide() {
      this.$emit('onHide');
    },
  },
};
</script>
